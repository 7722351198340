import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/vsts/work/1/s/src/layouts/mdlayout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEOPage = makeShortcode("SEOPage");
const BenefitsHeadingPara = makeShortcode("BenefitsHeadingPara");
const InformationCard = makeShortcode("InformationCard");
const BenefitsHeadingButton = makeShortcode("BenefitsHeadingButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOPage title="Benefits for Students" description="Explore how Dynos is beneficial for students" image="mathlesson" mdxType="SEOPage" />
    <BenefitsHeadingPara heading="Ditch the messy locker and boring worksheets" subHeading="Learn through fun interactive lessons, whether you’re in class or at home. Keep track of all your class notes, assignments, and reports in one place." button="Login" image="/students.png" className="students" mdxType="BenefitsHeadingPara" />
    <InformationCard name="expectancy" question="How likely does the user think they will be successful using the product? What resources do you have?" answer1="A comprehensive well laid out engaging digital curricula for all subjects relevant to their state or central syllabus along with an easy-to-navigate Learning Management System make students feel that they will be successful using Dynos." answer2="Students get personalized, immediate feedback on practice exercises which help them improve their learning." mdxType="InformationCard" />
    <InformationCard name="value" question="How will this product add value or address needs? Which needs is the product addressing?" answer1="Usefulness: Dynos covers every single topic from their prescribed syllabus for all subjects." answer2="Interest: To better understand the concepts, complex topics are explained with what-if scenarios so students can experiment with different sets of values along with explanatory videos." answer3="Importance: Students can do recap and review exercises multiple times to make sure they understand the concept." answer4="Students can interact with content in multiple ways such as playing with what-if scenarios, answering quick questions which help them" mdxType="InformationCard" />
    <InformationCard name="cost" question="What do users have to give up to use this product? How can we minimize this cost?" answer1="Students will follow Dynos digital content for all subjects without having to juggle through multiple learning platforms." answer2="Cost is losing flexibility of picking and choosing other publishers' boring static content in the form of PDFs but they can save time with one platform." mdxType="InformationCard" />
    <BenefitsHeadingButton heading="Get in touch with us to know more how Dynos can help a student to achieve excellence." button="Request a Demo" mdxType="BenefitsHeadingButton" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      